*{
  margin: 0;
  padding: 0;
  
}
body{
  overflow-x: hidden;
}
body:not(.root)::-webkit-scrollbar {
  width: 8px;
}

/* Track */
body:not(.root)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px none; 
  background-color: hsl(0, 0%, 78%) ;
  border-radius: 10px;
  /* margin-block: 0.5em; */
}
 
/* Handle */
body:not(.root)::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 20%); 
  height: 30vh;
  border-radius: 10px;
}


